<template>
  <div class="container mx-auto">
    <app-loading-page :text="$t('general.loading')" :loading="submitLoading"
      class="w-40 h-20 m-auto rounded-md shadow-md bg-transparent" />

    <app-modal-payout-detail :show="showModalPayoutDetail" @close="showModalPayoutDetail = false" :payout="selectPayout"
      processedPaymentLinkRoute="account.payout.transaction.payment-link" @update:payout="selectPayout = $event" />

    <!-- Download CSV Modal -->
    <app-modal :show="show_modal_download_csv" @close="show_modal_download_csv = false">
      <template v-slot:title>{{
          $t("payout.modal.download_csv_title")
      }}</template>
      <template v-slot:body>
        <p class="text-sm text-gray-500">
          {{ $t("payout.modal.download_csv_description") }}
        </p>
      </template>
      <template v-slot:action>
        <app-button @click="downloadCsvListPayout" :showf70Icon="false" type="button" class="sm:ml-3 sm:w-auto">
          {{ $t("payout.modal.download_csv_button") }}
        </app-button>
      </template>
    </app-modal>

    <!-- Upload Documents Modal -->
    <app-modal :show="show_modal_upload_docs" @close="show_modal_upload_docs = false" :showCancelButton="false">
      <template v-slot:title>
        <b class="capitalize">{{ $t("payout.upload_documents") }}</b>
      </template>
      <template v-slot:body>
        <div>
          <hr class="w-full border-gray-200 mb-5" />
          <app-form-input v-model="payout.reference_id" type="text" class="mb-8" :errorMessage="errors.reference_id"
            :placeholder="$t('payout.modal.insert_ref_id_placeholder')" />
          <hr class="w-full border-gray-200 mb-5" />
          <app-alert-error v-if="errors['document_path']" :message="errors['document_path'][0]"
            class="my-3 bg-red-100 p-3 rounded-md" />

          <app-alert-error v-if="mediaErrors['file']" :message="mediaErrors['file'][0]"
            class="my-3 bg-red-100 p-3 rounded-md" />
          <app-button-outline v-if="!documentFile.name" class="relative border-dashed w-full py-4"
            :class="{ 'border-red-500': errors['document_path'] || mediaErrors['file'] }"
            @click="show_modal_upload_docs = true">
            <span class="flex flex-col items-center gap-2">
              <app-icon name="PlusSmIcon" class="w-5" />
              <p>{{ $t("payout.modal.upload_proof_documents") }}</p>
            </span>
            <input type="file" class="
                  cursor-pointer
                  w-full
                  h-full
                  absolute
                  block
                  opacity-0
                  pin-r pin-t
                " @change="onFileChange" />
          </app-button-outline>
          <div v-if="loadingDocs" class="w-full flex justify-center py-8">
            <app-icon-loading iconColor="primary" class="" />
          </div>
          <div v-if="loadingDocs == false && documentFile.name" class="
                flex
                justify-between
                p-2
                border border-gray-200
                rounded-md
                w-auto
              ">
            <span class="text-gray-500 text-sm">{{ documentFile.name }}</span>
            <button @click="clearFile">
              <app-icon name="XIcon" class="w-5 text-gray-400 text-bold" />
            </button>
          </div>
        </div>
      </template>
      <template v-slot:action>
        <div class="flex flex-row space-x-2 my-2">
          <app-button @click="showModalMarkedAsFailed" width="" :showf70Icon="false" :primary="false"
            class="bg-red-100 text-red-600 hover:bg-red-200">{{ $t("payout.modal.mark_as_fail_button") }}</app-button>
          <app-button width="" @click="
            updatePayoutStatusApproved(payout.reference_id, documentPath)
          " :showf70Icon="false">{{ $t("payout.modal.mark_as_transferred_button") }}</app-button>
        </div>
      </template>
    </app-modal>

    <!-- Marked as failed modal-->
    <app-modal :show="show_modal_mark_as_failed" @close="show_modal_mark_as_failed = false">
      <template v-slot:title>{{
          $t("payout.modal.marked_as_failed_title")
      }}</template>
      <template v-slot:body>
        <p class="text-sm text-gray-500">
          {{ $t("payout.modal.mark_as_fail_description") }}
        </p>
        <app-form-textarea type="text" v-model="payout.reason" />
      </template>
      <template v-slot:action>
        <app-button @click="updatePayoutStatusRejected(payout.reason)" :showf70Icon="false" :primary="false"
          type="button" class="
              sm:ml-3 sm:w-auto
              bg-decline
              hover:bg-decline
              text-white
              outline-none
            ">
          {{ $t("payout.modal.mark_as_fail_button") }}
        </app-button>
      </template>
    </app-modal>

    <!-- Proceed Settlements Modal -->
    <app-modal :show="show_modal_proceed_settlements" @close="show_modal_proceed_settlements = false">
      <template v-slot:title>{{
          $t("payout.modal.proceed_to_settlement_title")
      }}</template>
      <template v-slot:body>
        <p class="text-sm text-gray-500">
          {{ $t("payout.modal.proceed_settlement_description") }}
        </p>
      </template>
      <template v-slot:action>
        <app-button @onClick="bulkUpdatePayoutStatusSettlements" @click="show_modal_proceed_settlements = false"
          :showf70Icon="false" type="button" class="sm:ml-3 sm:w-auto">
          {{ $t("payout.modal.proceed_to_settlement_button") }}
        </app-button>
      </template>
    </app-modal>

    <div class="mx-4 my-5 flex flex-row justify-between items-center">
      <span class="text-xl ml-3">{{ $t("payout.payment_list") }}</span>
      <app-button width="w-20 lg:w-1/6" v-if="isStatusRequested" :disabled="!select_table_request.length"
        :showf70Icon="false" @click="showModalSettlements">
        {{ $t("payout.modal.proceed_to_settlement_button") }}
      </app-button>
    </div>
    <hr class="order-b border-gray-200 mx-3" />
    <div class="container mx-auto">
      <app-table :tabs="tabs" :apiResponse="list_payout" :showEmptyButton="false"
        @pagination-per-page-selected="per_page_changed" @params-changed="params_changed" @tab-changed="tab_changed"
        @select-table-changed="select_table_changed" :selectable="isStatusRequested" :loading="loading && !indexClicked"
        :filters="[
          {
            key: 'filter[bank_id]',
            type: 'select',
            placeholder: $t('payout.table.account.header_bank_name'),
            items: banks,
          },
          {
            key: 'filter[account_no]',
            type: 'text',
            placeholder: $t('payout.table.account.header_acc_no'),
          },
          {
            key: 'filter[account_holder]',
            type: 'text',
            placeholder: $t('payout.table.account.header_acc_holder'),
          },
          {
            key: 'filter[amount]',
            type: 'number',
            placeholder: $t('payout.table.account.header_amount'),
          },
          {
            key: 'filter[business_name]',
            type: 'text',
            placeholder: $t('payout.table.account.header_business_name'),
          },
          {
            key: 'filter[currency]',
            type: 'text',
            placeholder: 'Currency',
          },
          {
            key: 'filter[created_at_between]',
            type: 'date',
            placeholder: 'Created At',
            value: '',
          },
        ]" @on-item-click="onItemClick">
        <template v-slot:button>
          <app-button @click="show_modal_download_csv = true" :showf70Icon="false" type="button"
            class="sm:ml-3 sm:w-auto">
            {{ $t("payout.modal.download_csv_button") }}
          </app-button>
        </template>
        <template v-slot:header>
          <td>
            <b>{{ LabelheaderDate }}</b>
          </td>
          <td>
            <b>{{ $t("payout.table.account.header_bank_name") }}</b>
          </td>
          <td>
            <b>{{ $t("payout.table.account.header_acc_no") }}</b>
          </td>
          <td>
            <b>{{ $t("payout.table.account.header_acc_holder") }}</b>
          </td>
          <td>
            <b>{{ $t("payout.table.account.header_amount") }}</b>
          </td>
          <td>
            <b>{{ $t("payout.table.account.header_business_name") }}</b>
          </td>
          <td v-if="isStatusProcessing || isStatusApproved">
            <b>{{ $t("payout.table.account.header_status") }}</b>
          </td>
          <td v-else></td>
        </template>
        <template v-slot:body="data">
          <td>
            <p>
              {{
                  $moment(
                    current_tab == PAYOUT_STATUS.REQUESTED
                      ? data.model?.created_at
                      : data.model?.updated_at
                  ).format("DD MMM YYYY hh:mm a")
              }}
            </p>
          </td>
          <td>
            <p>
              {{ data.model?.business?.bank?.bank.display_name }}
            </p>
          </td>
          <td>
            <p>{{ data.model?.business?.bank?.account_no ?? "-" }}</p>
          </td>
          <td>
            <p>{{ data.model?.business?.bank?.account_holder ?? "-" }}</p>
          </td>
          <td @click.stop>
            <div class="flex space-x-1 items-center">
              <app-text-tooltip :show="!data.model.round_off" :text="
                $t('wallet.payout.original_round_off', {
                  amount: $formats.currency(
                    data.model?.currency,
                    data.model?.round_off?.original_amount ?? 0.0
                  ),
                  type: data.model?.round_off?.type,
                })
              " :class="{
  'border-b border-dashed border-gray-400 cursor-help':
    data.model?.round_off,
}">
                <p>
                  {{
                      $formats.currency(
                        data.model?.currency,
                        data.model?.amount ?? 0.0
                      )
                  }}
                </p>
              </app-text-tooltip>
              <div v-if="showRoundOffAmountPayout(data.model)">
                <app-icon-loading v-if="data.model?._id === indexClicked" iconColor="gray-600" customClass="w-5 h-5" />
                <app-icon v-else @click="updateRoundOffAmountPayout(data.model)" name="SwitchVerticalIcon" class="
                      w-5
                      cursor-pointer
                      bg-gray-100
                      rounded-full
                      p-1
                      hover:bg-gray-200
                    " :class="
                      data.model.round_off ? 'text-green-500' : 'text-gray-600'
                    " />
              </div>
            </div>
          </td>
          <td>
            <p>
              {{ data.model?.business?.name }}
            </p>
          </td>
          <td @click.stop v-if="isStatusProcessing">
            <app-button-outline class="border-dashed" @click="showModalUploadDocs(data.model)">
              <span class="flex flex-row items-center gap-2">
                <app-icon name="PlusSmIcon" class="w-5" />
                <p>{{ $t("payout.upload_documents") }}</p>
              </span>
            </app-button-outline>
          </td>
          <td v-else-if="isStatusApproved">
            <app-button-outline class="bg-green-50 border-green-600">
              <span class="flex flex-row items-center gap-2">
                <app-icon name="CheckIcon" class="w-5" />
                <p>{{ $t("payout.transferred_button") }}</p>
              </span>
            </app-button-outline>
          </td>
          <td v-else></td>
        </template>
      </app-table>
    </div>
  </div>
</template>

<script>
import PAYOUT_STATUS from "@/utils/const/payout_status";
import appButton from "../../../../components/button/app-button.vue";

export default {
  components: { appButton },
  data() {
    return {
      PAYOUT_STATUS: PAYOUT_STATUS,
      show_modal_download_csv: false,
      show_modal_upload_docs: false,
      show_modal_mark_as_failed: false,
      show_modal_proceed_settlements: false,
      tabs: [],
      current_tab: null,
      filters: {},
      select_table_request: [],
      submitLoading: false,
      documentFile: [],
      documentPath: null,
      loadingDocs: false,
      indexClicked: null,
      showModalPayoutDetail: false,
      selectPayout: null
    };
  },
  created() {
    this.fetchListPayout();
  },
  computed: {
    list_payout() {
      return this.$store.getters["walletStore/list_payout"];
    },
    payout() {
      return this.$store.getters["walletStore/payout"];
    },
    loading() {
      return this.$store.getters["walletStore/loading"];
    },
    errors() {
      return this.$store.getters["walletStore/errors"];
    },
    mediaErrors() {
      return this.$store.getters["mediaStore/errors"];
    },
    banks() {
      return this.$store.getters["referenceStore/banks"];
    },
    isStatusRequested() {
      return [PAYOUT_STATUS.REQUESTED].includes(this.current_tab);
    },
    isStatusProcessing() {
      return [PAYOUT_STATUS.PROCESSING].includes(this.current_tab);
    },
    isStatusApproved() {
      return [PAYOUT_STATUS.APPROVED].includes(this.current_tab);
    },
    isStatusRejected() {
      return [PAYOUT_STATUS.REJECTED].includes(this.current_tab);
    },
    hasDocumentFile() {
      return this.documentFile !== "";
    },
    LabelheaderDate() {
      switch (this.current_tab) {
        case PAYOUT_STATUS.PROCESSING:
          return this.$t("payout.table.account.header_date_proceeded");
        case PAYOUT_STATUS.APPROVED:
        case PAYOUT_STATUS.REJECTED:
          return this.$t("payout.table.account.header_date_transferred");
        default:
          return this.$t("payout.table.account.header_date_requested");
      }
    },
  },
  mounted() {
    this.fetchListPayout();

    this.$store.dispatch("referenceStore/getBanks", 0);

    this.tabs = [
      { key: "requested", title: this.$t("payout.table.account.tab_request") },
      { key: "processing", title: this.$t("payout.table.account.tab_settlement") },
      { key: "approved", title: this.$t("payout.table.account.tab_approved") },
      { key: "rejected", title: this.$t("payout.table.account.tab_failed") },
    ];
  },
  methods: {
    select_table_changed(selected) {
      this.select_table_request = selected.map((obj) => {
        return obj._id;
      });
    },
    onFileChange(event) {
      this.clearFile();
      this.documentFile = event.target.files[0];
      this.loadingDocs = true;

      const form = new FormData();
      form.append("file", this.documentFile);

      this.$store
        .dispatch("mediaStore/uploadFile", {
          file: form,
        })
        .then((result) => {
          this.documentPath = result.data.media_path;
        })
        .catch((error) => {
          this.documentPath = null;
          this.documentFile = [];
        })
        .finally(() => {
          this.loadingDocs = false;
        });
    },
    clearFile() {
      this.documentPath = null;
      this.documentFile = [];
      this.clearError();
    },
    clearError() {
      this.$store.commit("walletStore/setErrorMessage");
      this.$store.commit("mediaStore/setErrorMessage");
    },
    params_changed(filters, queryParams) {
      this.filters = filters;
      this.filterFormatDate(filters["filter[created_at_between]"]);
      this.filters["filter[bank_id]"] = filters["filter[bank_id]"]?.id;
      this.fetchListPayout();
    },
    per_page_changed(per_page) {
      this.filters.per_page = per_page;
      this.fetchListPayout();
    },
    tab_changed(tab_name) {
      this.current_tab = tab_name;
    },
    async fetchListPayout() {
      this.getTabStatusTab();
      await this.$store.dispatch("walletStore/getListPayouts", this.filters);
    },
    async downloadCsvListPayout() {
      this.show_modal_download_csv = false;
      this.submitLoading = true;
      const csv_url = await this.$store.dispatch(
        "walletStore/getListPayoutsExports",
        this.filters
      );
      this.submitLoading = false;
      window.location.href = csv_url.data;
      this.fetchListPayout();
    },
    filterFormatDate(date) {
      let start = date?.start_date;
      let end = date?.end_date;

      if (date) this.filters["filter[created_at_between]"] = start + "," + end;
    },
    showModalMarkedAsFailed() {
      this.show_modal_upload_docs = false;
      this.show_modal_mark_as_failed = true;
    },
    getTabStatusTab() {
      var status_tab = null;
      let currentTab = this.current_tab ?? this.$route.query.tab;

      if (currentTab == "processing") {
        status_tab = PAYOUT_STATUS.PROCESSING;
      } else if (currentTab == "approved") {
        status_tab = PAYOUT_STATUS.APPROVED;
      } else if (currentTab == "rejected") {
        status_tab = PAYOUT_STATUS.REJECTED;
      } else {
        status_tab = PAYOUT_STATUS.REQUESTED;
      }

      this.current_tab = status_tab;

      this.filters["filter[status]"] = status_tab;
    },

    showModalSettlements() {
      this.show_modal_proceed_settlements = true;
    },

    async updateRoundOffAmountPayout(payout) {
      this.indexClicked = payout._id;

      if (payout.round_off == null) {
        await this.$store.dispatch(
          "walletStore/updateRoundOffAmountPayout",
          payout._id
        );
      } else {
        await this.$store.dispatch(
          "walletStore/cancelRoundOffAmountPayout",
          payout._id
        );
      }

      await this.fetchListPayout();

      this.indexClicked = null;
    },

    showRoundOffAmountPayout(payout) {
      let amountString = payout.amount?.toString();
      let showTabs = [PAYOUT_STATUS.REQUESTED, PAYOUT_STATUS.PROCESSING];

      if (amountString == null || amountString?.length == 0) {
        return null;
      }

      if (!showTabs.includes(this.current_tab)) {
        return null;
      }

      return amountString[amountString.length - 1] != 0;
    },

    showModalUploadDocs(payout) {
      this.clearFile();
      this.payout.reason = null;
      this.payout.reference_id = null;
      this.show_modal_upload_docs = true;
      this.$store.commit("walletStore/setPayout", payout);
    },

    async bulkUpdatePayoutStatusSettlements() {
      this.submitLoading = true;
      this.show_modal_proceed_settlements = false;
      var payout_ids = Object.values(this.select_table_request);

      const payoutResult = await this.$store.dispatch(
        "walletStore/bulkUpdatePayoutStatus",
        {
          payout_ids: payout_ids,
        }
      );

      this.submitLoading = false;

      if (payoutResult != null) {
        this.submitLoading = false;
        this.select_table_request = [];
        this.fetchListPayout();
      }
    },

    async updatePayoutStatusApproved(reference_id, document_path) {
      this.clearError();
      this.submitLoading = true;

      const payoutResult = await this.$store.dispatch(
        "walletStore/updatePayoutStatusWithDocuments",
        {
          status: PAYOUT_STATUS.APPROVED,
          reference_id: reference_id,
          document_path: document_path,
        }
      );

      this.submitLoading = false;

      if (this.$lodash.isEmpty(payoutResult.errors)) {
        this.show_modal_upload_docs = false;
        this.payout.reason = null;
        this.payout.reference_id = null;
        this.fetchListPayout();
        this.clearFile();

        this.$notify(
          {
            group: "success",
            title: this.$t("payout.alert_transferred_title"),
          },
          5000
        );
      }
    },

    async updatePayoutStatusRejected(reason) {
      this.clearError();
      this.submitLoading = true;
      this.show_modal_mark_as_failed = false;

      const payoutResult = await this.$store.dispatch(
        "walletStore/updatePayoutStatus",
        {
          status: PAYOUT_STATUS.REJECTED,
          reference_id: null,
          reason: reason,
        }
      );

      this.submitLoading = false;

      if (this.$lodash.isEmpty(payoutResult.errors)) {
        this.payout.reason = null;
        this.payout.reference_id = null;
        this.fetchListPayout();
        this.clearFile();
      }
    },

    onItemClick(data) {
      this.showModalPayoutDetail = !this.showModalPayoutDetail;
      this.selectPayout = data;
    },
  },
};
</script>
